import React from "react"
import { graphql } from "gatsby"

import useImages from "../hooks/useImages"
import useViewportSize, { SIZES } from "../hooks/useViewportSize"

import Layout from "../components/layout"
import Gallery from "../components/gallery"
import Section from "../components/section"
import Share from "../components/share"
import Separator from "../components/separator"

export default function Galeria({ data }) {
  const { hero } = useImages()
  const viewportSize = useViewportSize()
  const heroContent = <></>
  const photos = data.photos.edges.map(edge => edge.node.childImageSharp.fluid)

  return (
    <Layout
      heroImg={hero.childImageSharp}
      heroContent={heroContent}
      heroHeight={viewportSize === SIZES.XS ? "100px" : "250px"}
    >
      <Section
        style={{
          margin: "5rem 0",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <h2>Nuestras fotos</h2>
        <Separator />
      </Section>
      <div
        style={{
          maxWidth: "800px",
          margin: "auto",
        }}
      >
        <Gallery photos={photos} />
      </div>
      <Share />
    </Layout>
  )
}

export const query = graphql`
  query GaleriaImagesQuery {
    photos: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        absolutePath: { regex: "/gallery/" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 70) {
              src
              srcSet
              key: originalName
              height: presentationHeight
              width: presentationWidth
            }
          }
        }
      }
    }
  }
`
