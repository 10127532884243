import React from "react"
import { Link } from "gatsby"

import Separator from "./separator"
import Section from "./section"

export default function Share() {
  return (
    <Section
      style={{
        margin: "5rem 0",
        flexDirection: "column",
        textAlign: "center",
      }}
    >
      <h3
        style={{
          marginBottom: "1rem",
        }}
      >
        Ayúdanos a compartir nuestra historia
      </h3>
      <Separator />
      <Link
        to="https://www.instagram.com/aranchaypabloboda"
        target="_blank"
        rel="noopenner"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          lineHeight: "35px",
          margin: "0.5rem",
          textDecoration: "none",
          color: "#c38e9e",
        }}
      >
        <span
          style={{
            fontSize: "35px",
          }}
          className={"icon-instagram"}
        ></span>
        <h4
          style={{
            margin: "0 1rem",
          }}
        >
          #aranchaypablo
        </h4>
      </Link>
    </Section>
  )
}
